//MEMBER PHOTO
import Abigail from '../images/Members/Abigail.jpg'
import Arjun from '../images/Members/Arjun.jpg'
import Fauzan from '../images/Members/Fauzan.jpeg'
import Mayada from '../images/Members/Mayada.jpg'
import Anggun from '../images/Members/Anggun.jpg'
import Syawal from '../images/Members/Syawal.jpg'
import Davis from '../images/Members/Davis.jpg'
import Rojes from '../images/Members/Rojes.jpg'
import Raihan from '../images/Members/Raihan.jpg'
import Ilham from '../images/Members/Ilham.jpg'
import Gegel from '../images/Members/Gegel.jpg'
import Arisky from '../images/Members/Arisky.jpg'
import Fidiana from '../images/Members/Fidiana.jpg'
import Annisa from '../images/Members/Annisa.jpg'
import Ika from '../images/Members/Ika.jpg'
import Mutia from '../images/Members/Mutia.jpg'
import Zahwa from '../images/Members/Zahwa.jpg'
import Fadhil from '../images/Members/Fadil.jpg'
import Rakha from '../images/Members/Rakha.jpg'

//MEMBER PROJECTS
import ComClub from '../images/Projects/ComClub.png'
import Portofolio from '../images/Projects/Portofolio.png'
import AnonymousChat from '../images/Projects/AnonymousChat.png'
import ArduinoJS from '../images/Projects/ArduinoJS.png'
import Calculator from '../images/Projects/Calculator.jpg'
import TaskReminder from '../images/Projects/TaskReminder.png'
import FormLogin from '../images/Projects/FormLogin.png'
import WeatherPage from '../images/Projects/WeatherPage.png'
import ImageGallery from '../images/Projects/ImageGallery.png'
import MemberPage from '../images/Projects/MemberPage.png'
import AnakTrip from '../images/Projects/AnakTrip.png'
import BabyPosLogo from '../images/Projects/BabyPosLogo.jpg'
import BabyPos from '../images/Projects/BabyPos.png'
import Robot from '../images/Projects/Robot.jpg'
import ChocoPrime from '../images/Projects/ChocoPrime.jpg'
import AFA2024 from '../images/Projects/AFA2024.jpg'
import GalaxyDefiance from '../images/Projects/GalaxyDefiance.png'
import FlappyBird from '../images/Projects/FlappyBird.png'
import HealthyLife from '../images/Projects/HealthyLife.jpg'
import Photobooth from '../images/Projects/Photobooth.jpg'

export const divisions = [
  {
    title: "Web/Mobile Development",
    item: [
      {
        name: "VS Code",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/512px-Visual_Studio_Code_1.35_icon.svg.png?20210804221519",
      },
      {
        name: "HTML",
        image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
      },
      {
        name: "CSS",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png",
      },
      {
        name: "JavaScript",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
      },
      {
        name: "Bootstrap",
        image: "https://getbootstrap.com/docs/5.3/assets/brand/bootstrap-logo-shadow.png",
      },
      {
        name: "Android Studio",
        image: "https://developer.android.com/static/studio/images/new-studio-logo-1_1920.png",
      },
      {
        name: "Flutter",
        image: "https://cdn-images-1.medium.com/max/1200/1*5-aoK8IBmXve5whBQM90GA.png",
      },
      {
        name: "Firebase",
        image: "https://www.vectorlogo.zone/logos/firebase/firebase-icon.svg",
      },
      {
        name: "Git",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Git_icon.svg/2048px-Git_icon.svg.png",
      },
      {
        name: "GitHub",
        image:
          "https://cdn.icon-icons.com/icons2/2428/PNG/512/github_black_logo_icon_147128.png",
      },
      {
        name: "Figma",
        image:
          "https://cdn.icon-icons.com/icons2/2429/PNG/512/figma_logo_icon_147289.png"
      },
    ],
  },
  {
    title: "Competitive",
    item: [
      {
        name: "Olimpiade Sains Nasional (OSN)",
        image: "https://sman1-mlg.sch.id/wp-content/uploads/2023/04/OSN.png",
      },
      {
        name: "Olimpiade Penelitian Siswa Indonesia (OPSI)",
        image: "https://pusatprestasinasional.kemdikbud.go.id/uploads/event/nehMEajWuehXBwgmlibcOO217sTv4Y2oFyqSvS3J.png",
      },
      
      {
        name: "Indonesian Science Project Olympiad (ISPO)",
        image: "https://www.ispo.or.id/wp-content/uploads/2018/09/cropped-ispo-favicon.png",
      },
      {
        name: "Festival Inovasi dan Kewirausahaan Indonesia (FIKSI)",
        image: "https://smk.pusatprestasinasional.kemdikbud.go.id/fiksi/fiksi/img/logo.png",
      },
      
    ],
  },
  {
    title: "Game Development",
    item: [
      {
        name: "Godot",
        image: "https://upload.wikimedia.org/wikipedia/commons/6/6a/Godot_icon.svg",
      },
      {
        name: "Unity",
        image: "https://i.redd.it/tu3gt6ysfxq71.png",
      },
      {
        name: "Blender",
        image: "https://preview.redd.it/aangezien-blender-3d-een-nederlands-bedrijf-is-kunne-het-v0-98huavznnzdb1.png?auto=webp&s=b3c74dc09fe197617ba484a97575f7d7aa71df42",
      },
    ],
  },
  {
    title: "VR/AR Development",
    item: [
      {
        name: "Unity",
        image: "https://i.redd.it/tu3gt6ysfxq71.png",
      },
      {
        name: "Blender",
        image: "https://preview.redd.it/aangezien-blender-3d-een-nederlands-bedrijf-is-kunne-het-v0-98huavznnzdb1.png?auto=webp&s=b3c74dc09fe197617ba484a97575f7d7aa71df42",
      },
      {
        name: "Spark Studio",
        image: "https://res.cloudinary.com/startup-grind/image/upload/c_fill,dpr_2.0,f_auto,g_center,h_1080,q_100,w_1080/v1/gcs/platform-data-developercircles/events/SparkAR_Symbol_01_FullColor_g2Y5j0T.png",
      },
      
    ],
  },
];

export const leadership = [
  {
    id: 0,
    img: "https://i.ibb.co.com/9bkjjLn/241313878-387744446199154-3138488199834931165-n.jpg",
    name: "Taufiq Ismail, S. Kom",
    rank: "Pembimbing Club",
    grade: "Status: Guru Informatika",
    desc: "",
    socialMedia: {
      instagram: "https://instagram.com/taufiqaidi",
      facebook: "",
      youtube: "https://youtube.com/@TaufiqIsmail_id",
      linkedin: "https://www.linkedin.com/in/ismailtaufiq/",
      github: ""
    },
  },
  {
    id: 1,
    img: "https://i.ibb.co.com/NLjQ7Qm/449399881-1717836252392321-3972880974435570164-n.jpg",
    name: "David Pieter L, S.Si",
    rank: "Pembimbing Club",
    grade: "Status: Guru Robotik & MTK",
    desc: "",
    socialMedia: {
      instagram: "https://instagram.com/davidluhukay",
      facebook: "",
      youtube: "https://www.youtube.com/@DavidLuhukayProject",
      linkedin: "",
      github: ""
    },
  },
  {
    id: 2,
    img: Fauzan,
    name: "Fauzan Muttaqin",
    rank: "Ketua Club",
    grade: "Grade: XI MATH 2",
    socialMedia: {
      instagram: "https://instagram.com/fznmt_",
      youtube: "https://youtube.com/@fznmt",
      linkedin: "https://www.linkedin.com/in/fznmt/",
      github: "https://github.com/fznmt"
    },
  },
  {
    id: 3,
    img: Fidiana,
    name: "Fidiana Paskalin Timang Subagio",
    rank: "Wakil Ketua Club",
    grade: "Grade: XB ",
    socialMedia: {
      instagram: "",
      facebook: "",
      youtube: "",
      linkedin: "",
      github: ""
    },
  },
  {
    id: 4,
    img: Anggun,
    name: "Anggun Dwi Novi Ardani",
    rank: "Bendahara Club",
    grade: "Grade: XI MATH 2",
    desc: "",
    socialMedia: {
      instagram: "https://instagram.com/a.nggunovii__",
      facebook: "",
      youtube: "",
      linkedin: "",
      github: ""
    },
  },
  {
    id: 5,
    img: Annisa,
    name: "Annisa Rizka Nur F.",
    rank: "Sekretaris Club",
    grade: "Grade: XB",
    desc: "",
    socialMedia: {
      instagram: "",
      facebook: "",
      youtube: "",
      linkedin: "",
      github: ""
    },
  },
];

export const projects = [
  {
    id: 0, 
    title: "Informatics Club Website",
    author: "by Fauzan Muttaqin",
    description: "Sebuah website yang berisi mengenai informasi saya seperti perkenalan diri, sertifikat, riwayat pendidikan dan akun sosial media.",
    image: ComClub,
    tags: ["ReactJS", "JavaScript", "CSS"],
    category: "web dev",
    github: "",
    demo: "https://comclub.tech/",
    member: [
      {
        name: "Fauzan Muttaqin",
        img: Fauzan,
        instagram: "https://instagram.com/fznmt_",
        github: "https://github.com/fznmt",
      }
    ],
  },
  {
    id: 1,
    title: "Personal Portofolio",
    author: "by Fauzan Muttaqin",
    description: "Sebuah website yang berisi mengenai informasi saya seperti perkenalan diri, sertifikat, riwayat pendidikan dan akun sosial media.",
    image: Portofolio,
    tags: ["Next Js", "TypeScript", "JavaScript", "CSS", "TailwindCSS"],
    category: "web dev",
    github: "",
    demo: "",
    member: [
      {
        name: "Fauzan Muttaqin",
        img: Fauzan,
        instagram: "https://instagram.com/fznmt_",
        github: "https://github.com/fznmt",
      },
    ],
  },
  {
    id: 2,
    title: "Anonymous Chat",
    author: "by Fauzan Muttaqin",
    description: "Sebuah website memungkinkan kita untuk berkomunikasi ke orang yang sedang online (berada dalam website) secara anonim. Project ini saya buat pada tahun 2021 dan terdapat beberapa bug yang belum saya perbaiki.",
    image: AnonymousChat,
    tags: ["NodeJS", "JavaScript", "CSS", "HTML", "ExpressJS", "Socket.IO"],
    category: "web dev",
    github: "https://github.com/fznmt/Anonymous-Chat",
    demo: "",
    member: [
      {
        name: "Fauzan Muttaqin",
        img: Fauzan,
        instagram: "https://instagram.com/fznmt_",
        github: "https://github.com/fznmt",
      },
    ],
  },
  {
    id: 4,
    title: "Arduino.JS",
    author: "by Fauzan Muttaqin",
    description: "Sebuah website memungkinkan kita untuk mengontrol sebuah arduino seperti untuk menyalakan/mematikan LED atau sebagainya tanpa menggunakan koneksi internet/modul esp internet atau modul bluetooh. Cukup dengan port usb lalu di program untuk saling berkomunikasi melalui serialport.",
    image: ArduinoJS,
    tags: ["NodeJS", "JavaScript", "CSS", "HTML", "Socket.IO", "SerialPort"],
    category: "web dev",
    github: "",
    demo: "",
    member: [
      {
        name: "Fauzan Muttaqin",
        img: Fauzan,
        instagram: "https://instagram.com/fznmt_",
        github: "https://github.com/fznmt",
      },
    ],
  },
  {
    id: 6,
    title: "Simple Calculator App",
    author: "by Fauzan Muttaqin",
    description: "Sebuah aplikasi mobile/dekstop berupa kalkulator simpel yang dikembangkan dengan Flutter.",
    image: Calculator,
    tags: ["Flutter", "Mobile App", "Dekstop App"],
    category: "mobile dev",
    github: "",
    demo: "",
    member: [
      {
        name: "Fauzan Muttaqin",
        img: Fauzan,
        instagram: "https://instagram.com/fznmt_",
        github: "https://github.com/fznmt",
      },
    ],
  },
  {
    id: 7,
    title: "School Task Reminder",
    author: "by Fauzan Muttaqin",
    description: "Sebuah bot whatsapp yang terintegrasi dengan sebuah website php, tugas bot whatsapp adalah mengigatkan tugas secara otomatis ketika h-1 tugas di kumpulkan, membuat sticker, mengecek informasi atom, mengigatkan jadwal pelajaran.",
    image: TaskReminder,
    tags: ["NodeJS", "PHP", "CSS", "whatsapp-web.js", "JavaScript"],
    category: "web dev",
    github: "",
    demo: "",
    member: [
      {
        name: "Fauzan Muttaqin",
        img: Fauzan,
        instagram: "https://instagram.com/fznmt_",
        github: "https://github.com/fznmt",
      },
    ],
  },
  {
    id: 9 ,
    title: "Login Form",
    author: "by Rojes Hutepa",
    description: "Sebuah website design yang berisi page untuk login user.",
    image: FormLogin,
    tags: ["HTML", "CSS", "Bootstrap"],
    category: "Web Dev",
    github: "https://github.com/RojesHutapea/loginform",
    demo: "https://rojeshutapea.github.io/loginform/login.html",
    member: [
      {
        name: "Rojes Hutapea",
        img: Rojes,
        instagram: "https://instagram.com/jesajaboyy",
        github: "https://github.com/rojeshutapea",
      },
    ],
  },
  {
    id: 10,
    title: "Weather Page",
    author: "by Arizky Ibnu Ghifary",
    description: "Sebuah website design yang berisi page untuk melihat cuaca.",
    image: WeatherPage,
    tags: ["HTML", "CSS"],
    category: "Web Dev",
    github: "https://github.com/ikyy1233/weatherui",
    demo: "https://ikyy1233.github.io/weatherui/",
    member: [
      {
        name: "Arizky Ibnu Ghifary",
        img: Arisky,
        instagram: "https://instagram.com/shiinzz_",
        github: "https://github.com/ikyy1233",
      },
    ],
  },
  {
    id: 11,
    title: "Gallery Page",
    author: "by Davis Adhika Wirasatya",
    description: "Sebuah website yang berisi page untuk melihat kumpulan gambar.",
    image: ImageGallery,
    tags: ["HTML", "CSS", "JavaScript"],
    category: "Web Dev",
    github: "https://github.com/Daviswrstya/GalleryPage",
    demo: "https://daviswrstya.github.io/GalleryPage/",
    member: [
      {
        name: "Davis Adhika Wirasatya",
        img: Davis,
        instagram: "https://instagram.com/daxx_yo",
        github: "https://github.com/Daviswrstya",
      },
    ],
  },
  {
    id: 12,
    title: "Member Page",
    author: "by Arjun Pratama",
    description: "Sebuah website yang berisi page untuk melihat kumpulan anggota dari Club Informatika.",
    image: MemberPage,
    tags: ["HTML", "CSS", "Bootstrap"],
    category: "Web Dev",
    github: "https://github.com/jun150909/Member_Club_Infor",
    demo: "https://jun150807.github.io/Member_Club_Infor/",
    member: [
      {
        name: "Arjun Pratama",
        img: Arjun,
        instagram: "https://instagram.com/arjunnn_pratamaa",
        github: "https://github.com/Jun150807",
      },
    ],
  },
  {
    id: 13,
    title: "Anaktrip Website",
    author: "by Raihan Avignam",
    description: "Sebuah website yang berisi kenangan dan kumpulan anggota dari grup anak trip.",
    image: AnakTrip,
    tags: ["HTML", "CSS", "JavaScript", "Bootstrap"],
    category: "Web Dev",
    github: "https://github.com/Rainzzx04/anaktripp",
    demo: "https://rainzzx04.github.io/anakatripp/",
    member: [
      {
        name: "Raihan Avignam",
        img: Raihan,
        instagram: "https://instagram.com/rainzzx_04",
        github: "https://github.com/Rainzzx04",
      },
    ],
  },
  {
    id: 14,
    title: "BabyPos",
    author: "by Abigail Warouw & Ayu Puspa",
    description: "Smart Digital Posyandu, untuk memonitoring hasil pengecekan dan perhitungan pada ibu hamil dan balita serta menyediakan berbagai informasi edukatif mengenai oemenuhan kebutuhan ibu hamil dan balita disertai layanan konsultasi dengan pihak petugas kesehatan posyandu.",
    image: BabyPos,
    tags: ["Dart", "Flutter"],
    category: "Mobile Dev",
    github: "",
    demo: "",
    member: [
      {
        name: "BabyPos",
        img: BabyPosLogo,
        instagram: "https://instagram.com/babypos_id",
        github: "",
      },
      {
        name: "Ayu Puspa Angelica",
        img: Gegel,
        instagram: "",
        github: "",
      },
      {
        name: "Abigail R R Waraouw",
        img: Abigail,
        instagram: "",
        github: "",
      },
    ],
  },
  {
    id: 15,
    title: "3D Robot Enji",
    author: "by AR/VR Team",
    description: "3D Robot Enji adalah sautu filter yang dapat mendeteksi logo club computer dan dapat memunculkan 3D robot yang merupakan maskot club computer.",
    image: Robot,
    tags: ["Augmented Reality", "3D"],
    category: "vr/ar dev",
    github: "",
    demo: "https://www.instagram.com/ar/1267102211359603",
    member: [],
  },
  {
    id: 16,
    title: "AFA 2024 & 2025",
    author: "by AR/VR Team",
    description: "AFA 2024 merupakan filter instagram yang dibuat dalam rangka event Averos Fair 2024 dan pengguna dapat memilih berbagai bingkai menarik dalam filter.",
    image: AFA2024,
    tags: ["Instagram Filter"],
    category: "vr/ar dev",
    github: "",
    demo: "https://www.instagram.com/ar/1267102211359603",
    member: [],
  },
  {
    id: 16,
    title: "Flappy Bird",
    author: "by Muhammad Ilham Wiradisastra",
    description: "Flappy Bird adalah game arcade sederhana namun menantang, di mana pemain mengendalikan seekor burung yang terus bergerak maju. Tugas pemain adalah menjaga burung tersebut terbang dengan cara mengetuk layar agar burung tidak jatuh atau menabrak pipa yang menjadi rintangan di sepanjang jalur. Setiap kali burung berhasil melewati pipa, pemain akan mendapatkan satu poin. Meskipun tampak sederhana, permainan ini menuntut ketepatan timing dan refleks yang cepat untuk mendapatkan skor tinggi. Flappy Bird menawarkan pengalaman permainan yang adiktif dengan kontrol yang mudah dan grafis 2D yang lucu.",
    image: FlappyBird,
    tags: ["GoDot"],
    category: "game dev",
    github: "",
    demo: "",
    member: [
      {
        name: "Muhammad Ilham Wiradisastra",
        img: Ilham, 
        instagram: "",
        github: "",
      },
    ],
  },
  {
    id: 17,
    title: "Galaxy Defiance",
    author: "by Muhammad Ilham Wiradisastra",
    description: "Galaxy Defiance adalah game aksi 2D yang terinspirasi dari game klasik ala Nintendo dan Game Boy. Dalam game ini, pemain mengendalikan sebuah pesawat luar angkasa untuk melawan gelombang alien yang datang. Setiap kali pemain berhasil mengalahkan alien, mereka akan mengumpulkan poin yang dapat digunakan untuk meningkatkan kemampuan pesawat. Selain itu, pemain dapat mengambil berbagai power-up yang muncul di sepanjang permainan untuk membantu dalam pertempuran. Game ini dirancang menggunakan Godot Engine dan menawarkan pengalaman yang seru dan menegangkan dengan grafis retro yang nostalgik.",
    image: GalaxyDefiance,
    tags: ["GoDot"],
    category: "game dev",
    github: "",
    demo: "",
    member: [
      {
        name: "Muhammad Ilham Wiradisastra",
        img: Ilham, 
        instagram: "",
        github: "",
      },
    ],
  },
  {
    id: 18,
    title: "HealthyLife Calculator",
    author: "by Fauzan Muttaqin",
    description: "HealthyLife Calculator adalah sebuah web yang bertujuan untuk membantu Anda mengetahui seberapa ideal tinggi badan dan berat badan Anda. Dengan hasil yang diberikan dalam kategori seperti kurus, normal, dan gemuk, Anda akan mendapatkan saran dan panduan untuk mencapai keseimbangan yang lebih baik.",
    image: HealthyLife,
    tags: ["HTML", "CSS", "JavaScript"],
    category: "web dev",
    github: "",
    demo: "",
    member: [
      {
        name: "Fauzan Muttaqin",
        img: Fauzan,
        instagram: "https://instagram.com/fznmt_",
        github: "https://github.com/fznmt",
      },
    ],
  },
  {
    id: 19,
    title: "Self Photobooth",
    author: "by Fauzan Muttaqin",
    description: "Website ini digunakan untuk photobooth pada saat Averos Fair club informatika, pengguna dapat langsung mengirim foto ke WhatsApp secara otomatis.",
    image: Photobooth,
    tags: ["HTML", "CSS", "JavaScript", "API"],
    category: "web dev",
    github: "",
    demo: "",
    member: [
      {
        name: "Fauzan Muttaqin",
        img: Fauzan,
        instagram: "https://instagram.com/fznmt_",
        github: "https://github.com/fznmt",
      },
    ],
  },
];

export const achievements = [
  {
    id: 0,
    img: Arjun,
    name: "Arjun Pratama",
    status: "Olimpiade Sains Nasional",
    field: "Bidang Studi Informatika Terintegrasi",
    desc: "Arjun Pratama lolos ke tingkat Provinsi pada tahun 2023 dan lolos ke tingkat Nasional pada tahun 2024 di Olimpiade Sains Nasional (OSN) bidang studi Informatika Terintegrasi jenjang SMA, Mengikuti Academy of Champions (AOC) yang di selenggarakan oleh Ruangguru pada tahun 2025.",
    certificate: "",
  },
  {
    id: 1,
    img: Raihan,
    name: "Muhammad Raihan Avignam Zuhdi",
    status: "Olimpiade Sains Nasional",
    field: "Bidang Studi Informatika Terintegrasi",
    desc: "Muhammad Raihan Avignam Zuhdi lolos ke tingkat Provinsi lomba Olimpiade Sains Nasional (OSN) tahun 2024 bidang studi Informatika Terintegrasi jenjang SMA.",
    certificate: "",
  },
  {
    id: 2,
    img: Fauzan,
    name: "Fauzan Muttaqin",
    status: "Liga Digital Nasional & Bug Hunter",
    field: "Web Development & Cyber Security",
    desc: "Fauzan Muttaqin lolos ke tahap Semi Final pada Liga Digital Nasional (LDN) tahun 2024 bidang Web Development jenjang SMA. Dan mendapatkan sertifikat penghargaan/apresiasi karena telah menemukan bug/celah pada website milik perusahaan Savart EV.",
    certificate: "",
  },
  
];